<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/top.png"
    />
     <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">176****6142抢到了</li>
        <li class="scroll_item">131****7394抢到了</li>
        <li class="scroll_item">155****4549抢到了</li>
        <li class="scroll_item">130****5436抢到了</li>
        <li class="scroll_item">156****0054抢到了</li>
        <li class="scroll_item">171****5245抢到了</li>
        <li class="scroll_item">175****6540抢到了</li>
        <li class="scroll_item">185****0974抢到了</li>
      </ul>
    </div>
     <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
      />
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="changePhone"
      />
      <img
        class="two"
        v-if="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
      />
      <img
        class="three"
        v-if="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
      />
      <template>
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入验证码"
          :class="['input-code', { 'two-cell': num == 2 }]"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif"
        class="submit"
        @click="onSubmit"
      />
      <img
        class="four"
        v-if="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif"
      />
      
    </div>
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card1.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card2.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card3.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card4.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card5.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card6.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/card7.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/info.png"
    />
     <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/anhui/cloudAccelerationDouYin26/qdbs.png"
    />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common";

export default {
  mixins: [tailJumpMixin],
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "304208137128",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      dialog: false,
    };
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      // 返回拦截
      JuDuoDuo("100023", that.form.phone, "安徽联通26元云加速抖音组合包");
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/LTZheJiang/enjoy99`;
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href),
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changePhone(value) {
     
    },
    getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true;
        getVerificationCode(
          JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
        )
          .then((res) => {
            if (res.code !== 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              JuDuoDuo("100023", this.form.phone, "安徽联通26元云加速抖音组合包");
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          }).finally(() => {
            // 结束加载状态
            this.yzm_loading = false;
          });
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.dialog = false;
        if (res.code !== 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          JuDuoDuo("100023", this.form.phone, "安徽联通26元云加速抖音组合包");
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          JuDuoDuo("100023", this.form.phone, "安徽联通26元云加速抖音组合包");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      }  else {
        this.handleSubmit();
      }
    },
   
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f05465;
  height: 100%;
  overflow-y: auto;

  .img {
    width: 100%;
  }

  .scroll_tips {
    width: 100%;
    margin: -70px auto 0;
    overflow: hidden;

    .scroll_box {
      height: 61px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 61px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }

  .form {
    position: relative;
    padding: 60px 0;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(96px, -60px);
    }

    .two {
      width: 150px;
      right: 80px;
      top: 170px;
    }

    .three {
      width: 456px;
      transform: translate(96px, -20px);
    }

    .four {
      width: 310px;
      transform: translate(200px, -84px);
    }

    ::v-deep .van-cell {
      width: 636px;
      height: 110px;
      background-color: #ffffff;
      border-radius: 60px;
      margin: 20px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      // border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 50px;

        .code {
          width: 229px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #ff7900;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #ff7900;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }
    .submit {
      width: 636px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(0.9);
        }
      }
    }
   
  }
}
</style>